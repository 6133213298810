import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import BlogCard from './blogCard'


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: `2rem`,
    paddingBottom: `2rem`,
    flexGrow: 1,
  },
  paper: {
    width: `20rem`,
    height: `35rem`
  },
  control: {
    padding: theme.spacing(2),
  },
  blogItem: {
    height: `35%`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `baseline`,
    fontSize: `24px`,
    backgroundColor: `rgb(240,229,215)`,
    paddingLeft: `0px`,
  },
  blogItemDate: {
    color: `rgb(236,114,199)`,
    fontSize: `1.5rem`,
    flex: `none`
  },
  blogItemTitle: {
    color: `rgb(254, 255, 255)`,
    fontSize: `1.25rem`,
    flex: `none`
  },
  readMoreImg: {
    height: `65%`
  }
}))

const ReadMore = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 400)
            id
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              date(formatString: "MMMM DD, YYYY")
              featuredpost
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const { edges: posts } = data.allMarkdownRemark

  return (
    <>
      <Container maxWidth="md">
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h4" gutterBottom>
              Read more:
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid container justify="center" spacing={3}>
          {posts && posts.map(({ node: post }, index) => (
            index > 0 && index < 4 ?
            <Grid key={post.id} item>
              <BlogCard post={post} />
            </Grid>
            : null
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default ReadMore