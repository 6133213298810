import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash.kebabcase'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Content, { HTMLContent } from '../components/Content'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import PreviewCompatibleImage from '../components/previewCompatibleImage'
import Button from '@material-ui/core/Button'
import ColorfulDvdr from '../components/colorfulDvdr'
import ReadMore from '../components/readMore'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook';
import './blog-post.scss'

const useStyles = makeStyles((theme) => ({
  blogWraper: {
    backgroundColor: `rgb(240,229,215)`,
    paddingTop: `2rem`,
    paddingBottom: `7rem`,
    fontFamily: `Poppins`,
    fontSize: `16px`
  },
  dateAuthor: {
    color: `rgb(236,114,199)`,
    marginBottom: `2rem`
  },
  featuredImage: {
    boxShadow: '11px 10px rgb(233,114,199)',
  },
  latestAtLux: {
    marginBottom: `3rem`
  },
  contentWrapper: {
    paddingTop: `2rem`,
    paddingBottom: `2rem`
  },
  tagItem: {
    textTransform: 'none',
  },
}))

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  link,
  date,
  author,
  featuredImage
}) => {
  const PostContent = contentComponent || Content
  const classes = useStyles();

  return (
    <Box className={classes.blogWraper}>
      {helmet || ''}
      <Container>
        <Box id="blog-article" component="article">
          <Container maxWidth="md">
            <Typography variant="h3" component="h2" align="center">
              {title}
            </Typography>
            <Typography variant="h5" component="h5" align="center" className={classes.dateAuthor}>
              {`${date} | By ${author}`}
            </Typography>
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredImage,
                alt: `featured image thumbnail for post ${title}`,
              }}
              className={classes.featuredImage}
            />
          </Container>
          <Container maxWidth="md" className={classes.contentWrapper}>
            <PostContent content={content} /> 
            <a href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.luxperformingarts.com/${link}`} target="_blank" rel="noreferrer">
              <Button><FacebookIcon fontSize="large"/></Button>
            </a>        
            <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" target="_blank" rel="noreferrer">
              <Button><TwitterIcon fontSize="large"/></Button>
            </a>
            {tags && tags.length ? (
              <div style={{ marginTop: `2rem` }}>
                <Typography variant="h6" component="h4">
                  Tags
                </Typography>
                  {tags.map((tag) => (
                    <Link to={`/tags/${kebabCase(tag)}/`} key={tag + `tag`}>
                      <Button className={classes.tagItem}>{tag}</Button>
                    </Link>
                  ))}
              </div>
            ) : null}
          </Container>
          <Container maxWidth="md">
            <ColorfulDvdr />
          </Container>
          <ReadMore />
        </Box>
      </Container>
    </Box>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  link: PropTypes.string,
  tags: PropTypes.array,
  author: PropTypes.string,
  date: PropTypes.string
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        link={post.fields.slug}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        author={post.frontmatter.author}
        date={post.frontmatter.date}
        featuredImage={post.frontmatter.featuredimage}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        author
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`