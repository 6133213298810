import React, { useState } from 'react'

import PreviewCompatibleImage from './previewCompatibleImage'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Box from '@material-ui/core/Box'
import { Link } from 'gatsby'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: `20rem`,
    overflow: `hidden`
  },
  blogItem: {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `baseline`,
    fontSize: `24px`,
    background: `transparent`,
    paddingLeft: `0px`,
    height: `100%`
  },
  blogItemDate: {
    fontSize: `1.25rem`,
    flex: `none`,
    color: `rgb(16,16,17)`
  },
  blogItemTitle: {
    color: `rgb(236,114,199)`,
    fontSize: `1.5rem`,
    fontWeight: `900`,
    flex: `none`
  },
  readMoreImg: {
    height: `10rem`,  
    transition: `all 0.3s ease`,  
  },
  readMoreImgHov: {
    transform: `scale(1.05)`
  }
}))

const BlogCard = ({ post }) => {
  const classes = useStyles()
  const [isHovered, setHovered] = useState(false)

  const handleImgTrans = (isHovering) => {
    setHovered(isHovering)
  }

  return (
    <Link to={`/latestAtLux${post.fields.slug}`}>
      <Box className={classes.paper} onMouseEnter={() => handleImgTrans(true)} onMouseLeave={() => handleImgTrans(false)}>
        <PreviewCompatibleImage
          imageInfo={{
            image: post.frontmatter.featuredimage,
            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
          }}
          className={clsx({
            [classes.readMoreImg] : true,
            [classes.readMoreImgHov] : isHovered})}
        />
        <ListItem className={classes.blogItem} key={post.id}>
          <Typography className={classes.blogItemTitle}>
            {post.frontmatter.title}
          </Typography>
          <Typography className={classes.blogItemDate}>
            {post.frontmatter.date} 
          </Typography>
        </ListItem>
      </Box>
    </Link>
  )
}

export default BlogCard